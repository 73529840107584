import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ded61e5 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _15625487 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _1104d8ee = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _799c957b = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _2c130b04 = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _7423749d = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _4147e48c = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _6b98ccfe = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _1a251e4f = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _68b4a865 = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _25705394 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _0ec02884 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _14fd217a = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _5591307c = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _031ade56 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _0a182ddc = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _7c12757f = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _78e232c8 = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _241b9e5e = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _4a3c0507 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _7300c8c6 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _7ded61e5,
    name: "kits"
  }, {
    path: "/lojas",
    component: _15625487,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _1104d8ee,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _799c957b,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _2c130b04,
    name: "motos"
  }, {
    path: "/reset",
    component: _7423749d,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _4147e48c,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _6b98ccfe,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _1a251e4f,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _68b4a865,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _25705394,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _0ec02884,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _14fd217a,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _5591307c,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _031ade56,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _0a182ddc,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _7c12757f,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _78e232c8,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _241b9e5e,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _4a3c0507,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _7300c8c6,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
